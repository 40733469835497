<div class="page-container">
    <app-header></app-header>
  
    <div class="back-container">
      <div class="hero-container container">
        <div class="row align-items-center">
          <div class="data col-md-7 col-sm-12 ps-5">
            <h1>¡Gracias por tu compra!</h1>
            <p class="my-3">Estimado cliente su factura será enviada al correo proporcionado en breve.</p>
            <p-button class="fact-btn-raised" label="Volver al Inicio" (click)="goToHome()"></p-button>
          </div>
          <div class="col-lg-5 col-md-5 image-container">
            <img src="../../../assets/demo/images/factura-franquicias-vector.webp" width="400">
          </div>
        </div>
      </div>
    </div>
  
    <app-footer></app-footer>
  </div>