<div class="pt-4">
    <span class="p-float-label">
        <input pInputText maxlength="12" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="txtNota"
            [ngClass]="{'ng-invalid ng-dirty' : bNotaNo && !this.notaNo}" 
            style="text-transform:uppercase;width: 100%;"
            [(ngModel)]="notaNo" (ngModelChange)="setHide()" />
        <label>Nota No</label>
    </span>
    <small *ngIf="bNotaNo && !this.notaNo">
        *El numero de nota es requerido
    </small>
</div>
<div class="pt-4">
    <span class="p-float-label">
        <input pInputText id="txtSucursal" maxlength="4" [(ngModel)]="sucursalNo"
            onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" 
            (ngModelChange)="setHide()" [ngClass]="{'ng-invalid ng-dirty' : bSucursalNo && !this.sucursalNo}" 
            style="text-transform:uppercase;width: 100%;"/>
        <label for="txtSucursal">Sucursal</label>
    </span>
    <small *ngIf="bSucursalNo && !this.sucursalNo">
        *La sucursal es requerida
    </small>
</div>
<div class="pt-4">
    <span class="p-float-label">
        <p-calendar [(ngModel)]="fechaCompra" [readonlyInput]="true" id="txtFechaCompra" 
            dateFormat="dd M yy" [minDate]="minDateVal" [maxDate]="maxDateVal"
            [ngClass]="{'ng-invalid ng-dirty' : bFechaCompra && !this.fechaCompra}"></p-calendar>
        <label for="txtFechaCompra">Fecha de Compra</label>
    </span>
    <small *ngIf="bFechaCompra && !this.fechaCompra">
        *La fecha de compra es requerida
    </small>
</div>
<div class="pt-4">
    <span class="p-float-label w-100">
        <p-inputNumber id="txtTotal" [(ngModel)]="montoTotal" (ngModelChange)="setHide()" mode="decimal"
        class="number-input"   [minFractionDigits]="2" [ngClass]="{'ng-invalid ng-dirty' : bMontoTotal && !this.montoTotal}">
        </p-inputNumber>
        <label for="number-input">Monto Total</label>
    </span>
     <small *ngIf="bMontoTotal && !this.montoTotal">
        *El monto es rquerido
    </small> 
</div>
<div class="btn-container" >
    <p-button class="help-btn" (onClick)="showDialog('help-modal')" [text]="true" label=" Ayuda " icon="pi pi-question-circle" iconPos="right"  />    
</div>

<p-dialog 
    class="help-modal"
    header="Instrucciones" 
    [modal]="true"
    [(visible)]="display_help" 
    position="topright" 
    [style]="{ width: '25rem' }"
    [draggable]="false"
    [dismissableMask]="true">
    <div class="container">
        <div class="instruction">
            <p>Ingrese los siguientes datos del ticket de compra:</p>
            <ol style="padding: 0 0 25px 30px;list-style-type: circle;">
                <li>Número de Nota.</li>
                <li>Sucursal.</li>
                <li>Fecha.</li>
                <li>Total de compra.</li>
            </ol>
            <img       
            class="ticket-img"
            alt="Ticket de compra"
            src="../../../assets/demo/images/help/help-img-nota-1.webp"
            /><br><br>
            <p>Revise que los datos ingresados sean correctos y presione el botón "Siguiente".</p>
        </div>
    </div>
</p-dialog>