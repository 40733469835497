import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { datosNotaResponse } from '../../core/Interfaces/datos-nota-response';
import { tipoClienteSustituto } from '../../core/Interfaces/tipo-cliente-sustitutos';
import { FacturacionService } from '../../core/services/facturacion.service';
import {
  DatosClienteRFCRequest,
  DatosClienteRFCResponse,
} from '../../core/Interfaces/datos-cliente-rfc';
import { DatosNotaRequest } from '../../core/Interfaces/datos-nota-request';
import { DatosFacturaRequest } from '../../core/Interfaces/datos-factura';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-data-rfc',
  templateUrl: './data-rfc.component.html',
  styleUrl: './data-rfc.component.scss',
  providers: [MessageService],
})
export class DataRFCComponent implements OnInit {
  @Input({ required: true }) iRFC!: infoRFC;
  @Input() datosNotasRequest!: DatosNotaRequest;
  @Input() ClienteSustituto!: tipoClienteSustituto;
  @Input() DatosNotasResponse!: datosNotaResponse;
  @Output() onHide = new EventEmitter<boolean>();
  displayWait: boolean = false;

  selectClienteSustituto!: tipoClienteSustituto;

  datosClienteRFCResponse: DatosClienteRFCResponse = {
    Nombre: '',
    RFC: '',
    CP: '',
    Email: '',
    IdCliente: '',
    IdSustituto: '',
    RegimenFiscalSAT: '',
  };
  @Output() datosClienteRFCEmit = new EventEmitter<DatosClienteRFCResponse>();

  sRFC: string | undefined;
  sCP: string | undefined;
  sNombre: string | undefined;
  regimenes: any[] | undefined;
  selectedRegimen: any | undefined;
  selectedCFDI: any | undefined;
  sEmail: string = '';
  sRegimenSelec: string = '';
  //selectedRegimenVal: string = '';


  bRFC: boolean = false;
  bCP: boolean = false;
  bNombre: boolean = false;
  bRegimenSelec: boolean = false;
  bSelectedCFDI: boolean = false;
  bVEemail: boolean = false;
  bDisEemail: boolean = false;
  agreedToTerms: boolean = false;


  regimenesF!: any[];
  regimenesM!: any[];
 
  cfdi: any[] | undefined;
  cfdiAux: any[] = [];
  bEemail: boolean = false;
   
  datosClienteRFCRequest!: DatosClienteRFCRequest;
  datosFacturaRequest!: DatosFacturaRequest;
  sClientesustituto: string = '-1';
  
  tipoClienteSustitutos: tipoClienteSustituto[] = [];
  bShowSustitutos: boolean = true;

  messages!: Message[];

  constructor(
    private service: FacturacionService,
    public messageService: MessageService
  ) {

  }

  onRowSelect(event: any) {
    this.loadDataClient(this.selectClienteSustituto);
    this.sClientesustituto = this.selectClienteSustituto.IdSustituto;
  }

  onRowUnselect(event: any) {



    this.loadDataClient(this.selectClienteSustituto);
    this.sClientesustituto = this.selectClienteSustituto.IdSustituto;


    // this.sClientesustituto = '-1';
    // this.sRFC = '';
    // this.sNombre = '';
    // this.sCP = '';
    // this.sEmail = '';
    // this.cfdi = [];
    // this.regimenes = [];
  }

  sentDataRFCParent(): DatosFacturaRequest {
    this.datosFacturaRequest = {} as DatosFacturaRequest;
    this.datosFacturaRequest.IdGiro = '1';
    this.datosFacturaRequest.AvisoPrivacidad = this.agreedToTerms;

    //DatosNotasResponse
    if (this.selectClienteSustituto != undefined) {
      this.datosFacturaRequest.IdCliente =
        this.DatosNotasResponse.Data.IdCliente;
      this.datosFacturaRequest.IdSustituto =
        this.selectClienteSustituto.IdSustituto;
    } else {
      this.datosFacturaRequest.IdCliente =
        this.datosClienteRFCResponse.IdCliente;
      this.datosFacturaRequest.IdSustituto =
        this.datosClienteRFCResponse.IdSustituto;
    }

    this.datosFacturaRequest.PersonaMoral =
      (this.sRFC == undefined ? '' : this.sRFC).length == 13 ? '0' : '1';

    if(this.sRFC == undefined || this.sRFC == '') {this.bRFC = true }
    this.datosFacturaRequest.RFC =
      this.sRFC == undefined ? '' : this.sRFC.toUpperCase();

    if(this.sNombre == undefined || this.sNombre == '') {this.bNombre = true }
    this.datosFacturaRequest.Nombre =
      this.sNombre == undefined ? '' : this.sNombre.toUpperCase();

    if(this.sCP == undefined || this.sCP == '') {this.bCP = true }
    this.datosFacturaRequest.CP = this.sCP == undefined ? '' : this.sCP;
        
    if(this.selectedRegimen == undefined || this.selectedRegimen == '') {this.bRegimenSelec = true }
    this.datosFacturaRequest.RegimenFiscal = !this.selectedRegimen
    ? '' : this.selectedRegimen;
        
    if(this.selectedCFDI == undefined || this.selectedCFDI == '') {this.bSelectedCFDI = true }
    this.datosFacturaRequest.UsoCFDI = !this.selectedCFDI
      ? '' : this.selectedCFDI.code;
    
    if (this.bEemail && (this.sEmail == undefined || this.sEmail == '')){
      this.bVEemail = true;
    }
    this.datosFacturaRequest.Email = this.sEmail.toUpperCase();
    this.datosFacturaRequest.eMailEnable = this.bEemail;
    
    return this.datosFacturaRequest;


  }

  async ngOnInit() {
    this.displayWait = true;

    await this.fillRegimenFical();

    try {


      if(this.DatosNotasResponse.Data.Franquicia){
        this.bDisEemail = true;
        this.bEemail = true;
      }else{
        this.bDisEemail = false;
        this.bEemail = false;
      }
      


      this.messages = [
        { severity: 'warn', detail: 'Capture sus datos fiscales' },
      ];

      
  

      if (this.DatosNotasResponse.Data.lDatosFacturacionTitularSustituto) {
        let aTipoClienteSustituto: tipoClienteSustituto[] = [];

        if (
          this.DatosNotasResponse.Data.lDatosFacturacionTitularSustituto
            .length > 0
        ) { 
          for (
            let i = 0;
            i <
            this.DatosNotasResponse.Data.lDatosFacturacionTitularSustituto
              .length;
            i++
          ) {
            let clienteSustituto =
              this.DatosNotasResponse.Data.lDatosFacturacionTitularSustituto[i];
            clienteSustituto.Sustituto =
              clienteSustituto.IdSustituto == 0 ? false : true;
            aTipoClienteSustituto.push(clienteSustituto);
          }
          
          this.tipoClienteSustitutos = aTipoClienteSustituto;
          this.bShowSustitutos = false;
          this.messages = [
            { severity: 'warn', detail: 'Capture sus datos ficales' },
            { severity: 'warn', detail: 'PUEDE SELECCIONAR UN SUSTITUTO' },
          ]; 
          this.selectClienteSustituto = this.DatosNotasResponse.Data.lDatosFacturacionTitularSustituto[0];
          this.loadDataClient(this.selectClienteSustituto);
          this.sClientesustituto = this.selectClienteSustituto.IdSustituto;
        } 
      }
    
       



    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error inesperado consulte al Administrador',
      });
    } finally {
      this.displayWait = false;
    }
  }

  async fillRegimenFical() {
    let eFill = await this.service.getCFDIRF();

    this.cfdiAux = eFill.Data.ListaUsoCFDISAT;

    let DatosRFResponse = eFill.Data.ListaRegimenFiscalSAT.filter(
      (x: { Activo: boolean }) => x.Activo == true
    );
    let DatosCFDIResponse = eFill.Data.ListaUsoCFDISAT;

    this.regimenesF = [];
    DatosRFResponse.filter((y: { Moral: boolean }) => y.Moral == false).forEach(
      (x: any) => {
        this.regimenesF.push({
          code: x.IdRegimenFiscalSAT,
          name: x.Descripcion,
        });
      }
    );

    this.regimenesM = [];
    DatosRFResponse.filter((x: { Moral: boolean }) => x.Moral == true).forEach(
      (x: any) => {
        this.regimenesM.push({
          code: x.IdRegimenFiscalSAT,
          name: x.Descripcion,
        });
      }
    );
  }

  async loadDataClient(tipoClienteSustituto: tipoClienteSustituto) {
    this.sNombre = tipoClienteSustituto.Nombre;
    this.sRFC = tipoClienteSustituto.RFC;
    this.sCP = tipoClienteSustituto.CodigoPostal;

    if (tipoClienteSustituto.RegimenFiscalSAT.trim() != '') {
      this.sRegimenSelec =
        tipoClienteSustituto.RegimenFiscalSAT.split('-')[0].trim();
      this.selectedRegimen = this.sRegimenSelec;
      let event!: DropdownChangeEvent;
      this.changeRegimen(event);
    }

    if (tipoClienteSustituto.CorreoElectronico != '') {
      this.bEemail = true;
      this.sEmail = tipoClienteSustituto.CorreoElectronico;
    }

    if (tipoClienteSustituto.RFC)
      if (
        tipoClienteSustituto.RFC.length >= 12 &&
        tipoClienteSustituto.RFC.length <= 13
      )
        await this.validateRFC(false);

    this.sClientesustituto = tipoClienteSustituto.IdSustituto;
  }

  ngOnChanges() {
    if (this.iRFC['rfc']?.toString() != '') {
      this.sRFC = this.iRFC['rfc']?.toString();
      this.sNombre = this.iRFC['name']?.toString();
      this.sCP = this.iRFC['zipCode'];

      if (this.sClientesustituto != '') this.validateRFC();
    }
  }

  ngOnDestroy() {
    // this.eventsSubscription.unsubscribe();
  }

  setHide() {
    if (this.sRFC == '' || this.sCP == null || this.sNombre == null) {
      this.onHide.emit(true);
    } else {
      this.onHide.emit(false);
    }
  }

  handleChange(e: { checked: any }) {
    this.bEemail = e.checked;
    this.bVEemail = false;
    this.sEmail = '';
  }

  async queryRFC() {
    let response = await this.service.getClientRFC(this.datosClienteRFCRequest);
    this.datosClienteRFCResponse.IdCliente =
      !response.Data.IdCliente == true ? '' : response.Data.IdCliente;
    this.datosClienteRFCResponse.IdSustituto =
      response.Data.IdSustituto == undefined ? '0' : response.Data.IdSustituto;
    this.datosClienteRFCResponse.Nombre =
      !response.Data.Nombre == true ? '' : response.Data.Nombre;
    this.datosClienteRFCResponse.RFC =
      !response.Data.RFC == true ? '' : response.Data.RFC;
    this.datosClienteRFCResponse.CP =
      !response.Data.CodigoPostal == true ? '' : response.Data.CodigoPostal;
    this.datosClienteRFCResponse.Email =
      !response.Data.CorreoElectronico == true
        ? ''
        : response.Data.CorreoElectronico;
    this.datosClienteRFCResponse.RegimenFiscalSAT =
      !response.Data.RegimenFiscalSAT == true
        ? ''
        : response.Data.RegimenFiscalSAT;
    this.sCP =
      !response.Data.CodigoPostal == true ? '' : response.Data.CodigoPostal;
    this.sNombre = !response.Data.Nombre == true ? '' : response.Data.Nombre;
    this.sRegimenSelec =
      !response.Data.RegimenFiscalSAT == true
        ? ''
        : response.Data.RegimenFiscalSAT;
    this.bEemail = false;
    if (
      response.Data.CorreoElectronico != '' &&
      !!response.Data.CorreoElectronico
    ) {
      this.bEemail = true;
      this.sEmail = response.Data.CorreoElectronico;
    }

    // .promise(
    //             (response) =>{

    //               if (response.Result == 0){

    //                 this.datosClienteRFCResponse = {} as DatosClienteRFCResponse;
    //                 this.datosClienteRFCResponse.IdCliente = response.Data!? "":response.Data.IdCliente;
    //                 this.datosClienteRFCResponse.Nombre =  response.Data.Nombre;
    //                 this.datosClienteRFCResponse.RFC =  response.Data.RFC;
    //                 this.datosClienteRFCResponse.CP =  response.Data.CodigoPostal;
    //                 this.datosClienteRFCResponse.Email =  response.Data.CorreoElectronico;
    //                 this.sCP =  response.Data.CodigoPostal;
    //                 this.sNombre = response.Data.Nombre;
    //                 if (response.Data.CorreoElectronico != '' ){
    //                     this.bEemail = true;
    //                     this.sEmail = response.Data.CorreoElectronico;
    //                 }
    //               }
    //               else{

    //                 this.sCP =  "";
    //                 this.sNombre = "";
    //                 this.sEmail = "";

    //               }

    //             },
    //             (error) =>{
    //               console.error(error);
    //             }
    //           );
  }

  async validateRFC(getClientRFC: boolean = true) {
    this.selectedRegimen = '';
    this.selectedCFDI = '';
    this.sClientesustituto = '-1';

    if (
      this.sRFC?.toString().length == 12 ||
      this.sRFC?.toString().length == 13
    ) {
      if (getClientRFC) {
        this.datosClienteRFCRequest = {} as DatosClienteRFCRequest;
        this.datosClienteRFCRequest.Nota = {} as DatosNotaRequest;
        this.datosClienteRFCRequest.RFC = this.sRFC;
        this.datosClienteRFCRequest.Nota.PuntoVenta =
          this.datosNotasRequest.PuntoVenta;

        await this.queryRFC();

        this.datosClienteRFCEmit.emit(this.datosClienteRFCResponse);
      }

      if (this.sRFC?.toString().length == 13) {
        this.regimenes = this.regimenesF;
      } else if (this.sRFC?.toString().length == 12) {
        this.regimenes = this.regimenesM;
      } else {
        this.regimenes = [];
      }

      if (this.sRegimenSelec != '' && !!this.sRegimenSelec) {
        this.selectedRegimen = this.sRegimenSelec; //this.regimenesF.find(x => x.code == this.sRegimenSelec) //{ name: '', code: this.sRegimenSelec };
        let event!: DropdownChangeEvent;
        this.changeRegimen(event);
      }
    }
  }

  changeRegimen($event: DropdownChangeEvent): void {
    this.selectedCFDI = null;
    this.cfdi = [];
    this.cfdiAux
      .filter((x) => x.RegimenFiscalSAT == this.selectedRegimen)
      .forEach((y: any) => {
        this.cfdi?.push({
          code: y.IdUsoCFDISAT,
          name: y.Descripcion,
        });
      });
  }

  
 
}

export interface infoRFC {
  rfc?: string;
  name?: string;
  zipCode?: string;
}
