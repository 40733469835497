import { Component, Input, OnInit, input } from '@angular/core';
import { DatosFacturaResponse } from '../../core/Interfaces/datos-factura';
import {
  DatosFacturaExistente,
  data,
} from '../../core/Interfaces/datos-factura-existente';

@Component({
  selector: 'app-cfdi-download',
  templateUrl: './cfdi-download.component.html',
  styleUrl: './cfdi-download.component.scss',
})
export class CfdiDownloadComponent implements OnInit {
  @Input() bFran!: boolean;
  @Input() sRFC: string = '';
  sXML!: string;
  sPDF!: string;
  datosFacturaResponse!: DatosFacturaResponse;

  bFraiche: boolean = true;

  dataE: data = {
    ruta: '',
    zipPdf: '',
    zipXml: '',
    filePdf: '',
    fileXml: '',
    zipFactura: '',
  };

  @Input() facturaFile: DatosFacturaExistente = {
    Data: this.dataE,
    Result: 0,
    Message: '',
  };

  constructor() {

  }

  

  ngOnInit() {

    if (this.bFran) this.bFraiche = true;
    else this.bFraiche = false;
  }

  downLoadFactura() {
    //this.sPDF = this.datosFacturaResponse.pdf;
    let byteArray = new Uint8Array(
      atob(this.facturaFile.Data.zipFactura)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    let file = new Blob([byteArray], { type: 'application/zip' });
    let fileURL = URL.createObjectURL(file);
    let fileName = this.sRFC;
    let link = document.createElement('a');
    link.download = fileName;
    link.target = '_blank';
    link.href = fileURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
