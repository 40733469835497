import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { DatosNotaRequest } from '../../core/Interfaces/datos-nota-request';
import { FormGroup, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

imports: [PrimeNGConfig, EventEmitter, FormsModule];

@Component({
  selector: 'app-data-nota',
  templateUrl: './data-nota.component.html',
  styleUrl: './data-nota.component.scss',
})
export class DataNotaComponent implements OnInit {
  public eNota!: DatosNotaRequest;

  @Output() onHide = new EventEmitter<boolean>();

  
   dateNow: Date = new Date();
   minDateVal = new Date(   (this.dateNow.getMonth()) + '/' + (this.dateNow.getDay()) +'/' + (this.dateNow.getFullYear() - 5) );
   maxDateVal = new Date(); 
  
  notaNo: string = '';
  sucursalNo: string = '';
  fechaCompra: Date = new Date();
  montoTotal!: number;

  bNotaNo: boolean = false;
  bSucursalNo: boolean = false;
  bFechaCompra: boolean = false;
  bMontoTotal: boolean = false;
  display_help: boolean = false; 


  constructor(private config: PrimeNGConfig, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.notaNo = params['nota'];
      this.sucursalNo = params['pv'];
      this.fechaCompra = params['fecha'];
      this.montoTotal = params['monto'];
    });
  }

  sentDataNotaParent() {


    this.eNota = {} as DatosNotaRequest;

    if (this.notaNo == '' || this.notaNo == undefined) this.bNotaNo = true;

    if (this.sucursalNo == '' || this.sucursalNo == undefined) this.bSucursalNo = true;

    if (this.fechaCompra == undefined) this.bFechaCompra = true;

    if (this.montoTotal == null || this.montoTotal == undefined)   this.bMontoTotal = true;

    this.eNota.Nota = !this.notaNo ? '' : this.notaNo;
    this.eNota.PuntoVenta = !this.sucursalNo ? '' : this.sucursalNo;

      let fechaAuxCompra  = !this.fechaCompra? "": this.fechaCompra
      .toString()
      .replace('Ene', 'Jun')
      .replace('Abr', 'Apr')
      .replace('Agt', 'Aug')
      .replace('Dic', 'Dec');

    this.eNota.FechaOperacion =  new Date(fechaAuxCompra); 
    //new Date(   (this.fechaCompra.getFullYear() - 5) +'/' + 
    //                                          (this.fechaCompra.getMonth()) + '/' + (this.fechaCompra.getDay())  );
    //this.fechaCompra;
    //this.fechaCompra.toString()
    this.eNota.MontoTotal = this.montoTotal;

    return this.eNota;
  }



  ngOnInit(): void {
    this.config.setTranslation({
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Agt',
        'Sept',
        'Oct',
        'Nov',
        'Dic',
      ],
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    });
  }

  setHide() {
    if (
      this.notaNo == '' ||
      this.sucursalNo == '' ||
      this.fechaCompra == null ||
      this.montoTotal == null
    ) {
      this.onHide.emit(true);
      this.onHide.emit(false);
    } else {
      this.onHide.emit(false);
    }
  }

  showDialog(modal: string) {    
    if (modal == 'help-modal') {
      this.display_help = true;
    }
  }
}
