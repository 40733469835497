<div class="">
    <p-messages [(value)]="messages" [enableService]="false" [closable]="true" />
</div>


<div class="table-section" [hidden]="bShowSustitutos">

    <p-divider align="left" type="solid">
        <b>Adicionales</b>
    </p-divider>

    <p-table [value]="tipoClienteSustitutos" selectionMode="single" [(selection)]="selectClienteSustituto"  
        dataKey="IdSustituto" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"   
        [metaKeySelection]="true" [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>Nombre</th>
                <th>RFC</th>
                <th>Codigo Postal</th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tipoClienteSustituto>
            <tr [pSelectableRow]="tipoClienteSustituto">
                <td>
                    <img src=".../../assets/demo/images/icono.png" [hidden]="tipoClienteSustituto.Sustituto" alt=""
                        width="20">

                </td>
                <td>{{tipoClienteSustituto.Nombre}}</td>
                <td>{{tipoClienteSustituto.RFC}}</td>
                <td>{{tipoClienteSustituto.CodigoPostal}}</td>

            </tr>
        </ng-template>


    </p-table>

</div>

<div class="field col-12 md:col-12">
    <p-divider align="left" type="solid" class="field col-12 md:col-12">
        <b>Datos Fiscales</b>
    </p-divider>
</div>

<div class="field col-12 md:col-6">
    <span class="p-float-label">
        <input required pInputText id="txtFRC" maxlength="13" [(ngModel)]="sRFC" (ngModelChange)="validateRFC()"
            onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" style="text-transform:uppercase;width: 100%;"
            (ngModelChange)="setHide()" [ngClass]="{'ng-invalid ng-dirty' : bRFC && !this.sRFC}" />
        <label for="txtFRC">RFC</label>
    </span>
    <small *ngIf="bRFC && !this.sRFC">
        *El RFC es requerido
    </small>
</div>

<div class="field col-12 md:col-6">
    <span class="p-float-label">
        <input required pInputText [(ngModel)]="sCP" id="txtCP" maxlength="5" style="width: 100%;"
            onkeypress="return /[0-9]/i.test(event.key)" [ngClass]="{'ng-invalid ng-dirty' : bCP && !this.sCP}" />
        <label for="txtCP">Código Postal</label>
    </span>
    <small *ngIf="bCP && !this.sCP">
        *El codigo postal es requerido
    </small>
</div>

<div class="class=field col-12 pt-4">
    <span class="p-float-label">
        <input pInputText required id="txtNombre" required [(ngModel)]="sNombre" (ngModelChange)="setHide()"
            style="width: 100%;" onkeypress="return /[0-9a-zA-Z-\s]/i.test(event.key)"
            [ngClass]="{'ng-invalid ng-dirty' : bNombre && !this.sNombre}" />
        <label for="txtNombre">Nombre / Razón Social</label>
    </span>
    <small *ngIf="bNombre && !this.sNombre">
        *El nombre / razón social es requerido
    </small>
</div>

<div class="class=field col-12 mt-4">
    <span class="p-float-label">
        <p-dropdown required [options]="regimenes" [(ngModel)]="selectedRegimen" placeholder="Regímenes"
            (onChange)="changeRegimen($event)" #selectedRegimenVal optionLabel="name" inputId="float-label"
            dataKey="selectedRegimen" optionValue="code" [style]="{'minWidth':'50px', 'width': '100%'}"
            [ngClass]="{'ng-invalid ng-dirty' : bRegimenSelec && !this.selectedRegimen}"></p-dropdown>
        <label for="float-label">Regímenes</label>
    </span>
    <small *ngIf=" bRegimenSelec && !this.selectedRegimen">
        *El régimen fiscal es requerido
    </small>
</div>

<div class="class=field col-12 mt-4">
    <span class="p-float-label">
        <p-dropdown required [options]="cfdi" [(ngModel)]="selectedCFDI" [style]="{'minWidth':'50px', 'width': '100%'}"
            placeholder="Uso de CFDI" optionLabel="name" inputId="float-label"
            [ngClass]="{'ng-invalid ng-dirty' : bSelectedCFDI && !this.selectedCFDI}"></p-dropdown>
        <label for="float-label">Uso de CFDI</label>
    </span>
    <small *ngIf=" bSelectedCFDI && !this.selectedCFDI">
        *El uso CFDI es requerido
    </small>
</div>

<div class="row justify-content-start w-100 mt-4 mail-input">
    <div class="fcol-12 md:col-4 sm:col-3 h-auto switch-mail">
        <div style="float:left">
            <p-inputSwitch [(ngModel)]="bEemail" (onChange)="handleChange($event)"
                [disabled]="bDisEemail"></p-inputSwitch>
        </div>
        <div style="float: left;" class="ms-3">
            <span class="text-900" style="padding-top:10px">Enviar factura</span>
        </div>
    </div>

    <div class="col-12  md:col-8 sm:col-9 mail-input-rfc" [hidden]="!bEemail">
        <span class="p-float-label">
            <input pInputText id="txtCorreo" type="sEmail" [(ngModel)]="sEmail" style="width: 100%;"
                (ngModelChange)="setHide()" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                [ngClass]="{'ng-invalid ng-dirty' : bEemail && bVEemail && !this.sEmail}" />
            <label for="txtCorreo">Correo</label>
        </span>
        <small *ngIf="bEemail &&  bVEemail && !this.sEmail">
            *El correo es requerido
        </small>
    </div>

    <div class="class=field col-12 mt-4" style="display: flex; align-items: start;">
        <p-checkbox name="termsCheckbox" [(ngModel)]="agreedToTerms" binary="true">
        </p-checkbox>
        <label style="margin-left: 8px;" for="termsCheckbox">He leído y acepto el <a
                href="https://perfumesfraiche.com/conten/pdfs/AvisoDePrivacidad.pdf" target="_blank">Aviso de
                Privacidad</a> puesto
            a mi disposición en el enlace activo.</label>
    </div>
</div>

<div class="progress-spinner" *ngIf="displayWait">
    <p-progressSpinner></p-progressSpinner>
</div>