export const environment = {

    production:false,
    baseURL: 'https://mi.fraiche.com.mx/facturacionapi/',
    firebaseConfig : {
        apiKey: "AIzaSyAhNcxMza5QtZKHeuZFCIJEtFlL4VZnGGw",
        authDomain: "pos-facturacion-qa.firebaseapp.com",
        projectId: "pos-facturacion-qa",
        storageBucket: "pos-facturacion-qa.appspot.com",
        messagingSenderId: "101101978605",
        appId: "1:101101978605:web:1fcc07dd716fdc1a9a9de4",
        measurementId: "G-JT895SEHMP"
      }
    
};
