<div class="download-file" [hidden]="bFraiche">
    <div class="title w-100 text-center">
        <h2 class="">Factura generada exitosamente</h2>
    </div>
    <div class="download-container">
        <div class="custom-button" (click)="downLoadFactura()" style="cursor: pointer;">
            <div class="icon-container">
                <img class="icon" alt="zip icon" src="../../../assets/demo/images/zip-download-icon.webp" />
            </div>
            <!-- <p > &nbsp;&nbsp; {{  sRFC  }}  &nbsp;&nbsp;</p> -->
            <div class="text-container">
                <p> &nbsp;&nbsp; {{ sRFC }} &nbsp;&nbsp;</p>
            </div>
        </div>
        <small>
            La factura ya ha sido enviada al correo registrado. <br>
            También puede descargar el archivo.
        </small>
    </div>
</div>


<div class="back-container" style="margin: 40px auto;" [hidden]="!bFran">
    <div class="title w-100 text-center">
        <h2 class="">¡Gracias por tu compra!</h2>
        <p>Estimado cliente, su factura será enviada al correo proporcionado en un lapso no mayor a
            48 horas hábiles.</p>
        <p>Si tienes alguna duda o comentario, no dudes en ponerte en contacto con nuestro equipo de Servicio a Clientes. Estaremos encantados de ayudarte de lunes a viernes de 9:00 a 19:00 hrs y los sábados de 10:00 a 14:00 hrs a través de los siguientes medios.</p>
        <p>Tel. 800 2010 118 Opción 5<br>WhatsApp: 55 1801 7964<br><a href="mailto:servicio.clientes&#64;fraiche.com.mx">servicio.clientes&#64;fraiche.com.mx</a><br><a href="https://fraiche.com.mx">https://fraiche.com.mx</a></p>
    </div>
    <div class="w-100 text-center">
        <img class="vect-1" alt="factura icon" src="../../../assets/demo/images/factura-franquicias-vector.webp"
            style="margin:0 auto" width="300" />
    </div>
</div>



<div class="ad-container col-12 text-end">
    <a class="d-block" href="https://fraiche.com.mx/fraichestorefront/es/"><img
            src="https://perfumesfraiche.com/facturacion/ads/ads-front-facturacion.webp" class="ad-img"></a>
    <small class="d-block mt-2"><img src="https://perfumesfraiche.com/facturacion/ads/ad-icn.webp">Publicidad</small>
</div>


<!-- 

<div class="col-12">
    <div class="card" [hidden]="bFraiche">

        <div class="p-fluid p-formgrid grid" (click)="downLoadFactura()">

            <div class="field col-12 md:col-2 align-content-center text-left">
                <img class="max-w-2rem" alt="dropdown icon" src="../../../assets/demo/images/zip.png">
            </div>

            <div class="field col-12 md:col-7">
                <p> &nbsp;&nbsp; {{ sRFC }} &nbsp;&nbsp;</p>
            </div>

        </div>
    </div>

    <div  class="card" [hidden]="!bFran">
      
        <div class="p-fluid p-formgrid grid" >
            <div class="align-content-center text-center">
                <h1> Estimado cliente su factura será enviada al correo proporcionado en breve </h1>
            </div>
        </div>

    </div>

</div> -->