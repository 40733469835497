import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { datosNotaResponse } from '../Interfaces/datos-nota-response';
import { loginRequest } from '../Interfaces/login';
import {
  HttpErrorHandler,
  HandleError,
} from '../services/http-error-handler.service';
import { TokenService } from './token.service';
import { DatePipe } from '@angular/common';
import { DatosNotaRequest } from '../Interfaces/datos-nota-request';
import { DatosClienteRFCRequest } from '../Interfaces/datos-cliente-rfc';
import { DatosFacturaRequest } from '../Interfaces/datos-factura';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class FacturacionService {
  private handleError!: HandleError;
  sToken!: string;

  private uriLogin = environment.baseURL + 'AuthenticationJwt';
  private uriFaturacion = environment.baseURL + 'Facturacion';

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    token: TokenService
  ) {
    this.handleError = httpErrorHandler.createHandleError('FacturaService');
  }

  login(login: loginRequest): Promise<any> {
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<any>(this.uriLogin + '/AutenticaUsuario', login, httpOptions)
      .pipe(catchError(this.handleError('Login', login)))
      .toPromise();
  }

  getValidateNota(nota: DatosNotaRequest): Promise<any> {
    const dateNow = new DatePipe('en-US').transform(
      nota.FechaOperacion,
      'yyyy-MM-dd'
    );

    var uri =
      this.uriFaturacion +
      '/ValidaNota?' +
      'Nota=' +
      nota.Nota +
      '&PuntoVenta=' +
      nota.PuntoVenta +
      '&FechaOperacion=' +
      dateNow +
      '&MontoTotal=' +
      nota.MontoTotal;
    return this.http
      .get<any>(uri)
      .pipe(catchError(this.handleError('validateNota', nota)))
      .toPromise();
  }

  getClientRFC(datosClienteRFCRequest: DatosClienteRFCRequest): Promise<any> {
    var uri = this.uriFaturacion + '/ClientePorRFC';

    //let data =  this.http.post<any>(uri, datosClienteRFCRequest , httpOptions).toPromise();

    return this.http
      .post<any>(uri, datosClienteRFCRequest, httpOptions)
      .pipe(
        catchError(this.handleError('getClientRFC', datosClienteRFCRequest))
      )
      .toPromise();
  }

  generateFactura(datosFacturaRequest: DatosFacturaRequest): Promise<any> {
    var uri = this.uriFaturacion + '/GeneraFactura';

    return this.http
      .post<any>(uri, datosFacturaRequest, httpOptions)
      .pipe(
        catchError(this.handleError('generateFactura', datosFacturaRequest))
      )
      .toPromise();
  }


  







  getExistingFactura(nota: DatosNotaRequest): Promise<any> {
    var uri = this.uriFaturacion + '/ObtenerArchivosCFDI';

    return this.http
      .post<any>(uri, nota, httpOptions)
      .pipe(catchError(this.handleError('obtenerFacturaExistente', nota)))
      .toPromise();
  }

  getCFDIRF(): Promise<any>{
    var uri = this.uriFaturacion + '/Obtener_RF_CFDI';

    return this.http
      .get(uri, httpOptions)
      .pipe(catchError(this.handleError('getCFDIRF')))
      .toPromise();
  }









}
