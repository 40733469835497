import { Component } from '@angular/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  display_faq_modal: boolean = false;

  showFAQDialog() {
    this.display_faq_modal = true;
  }



}
