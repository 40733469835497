<header>
	<div id="top-bar">
		<div class="container">
			<ul class="list-unestyled list-inline">
				<!-- My Account -->
				<li class="liOffcanvas list-inline-item">
						<a href="https://fraiche.com.mx/fraichestorefront/es/login">
								<span>
									<svg width="18" height="17" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.92059 6.93334C8.98246 6.93334 10.6539 5.38126 10.6539 3.46667C10.6539 1.55208 8.98246 0 6.92059 0C4.85873 0 3.18726 1.55208 3.18726 3.46667C3.18726 5.38126 4.85873 6.93334 6.92059 6.93334Z" fill="white"></path>
										<path d="M13.5049 12.5323C13.3751 12.6777 13.2126 12.7948 13.0289 12.8756C12.8451 12.9564 12.6445 12.9988 12.4412 12.9999H1.39989C1.19652 12.9992 0.995761 12.9574 0.811457 12.8776C0.627154 12.7978 0.463699 12.6817 0.332374 12.5376C0.201049 12.3934 0.104982 12.2244 0.0508024 12.0424C-0.00337724 11.8604 -0.0143789 11.6696 0.0185567 11.4833C0.20044 10.4634 0.642779 9.4988 1.30855 8.67024C1.97432 7.84168 2.84415 7.17327 3.84523 6.72095C4.69468 7.41629 5.78817 7.79995 6.92056 7.79995C8.05296 7.79995 9.14645 7.41629 9.9959 6.72095C10.997 7.17327 11.8668 7.84169 12.5326 8.67024C13.1983 9.4988 13.6407 10.4634 13.8226 11.4833C13.8547 11.6691 13.843 11.8591 13.7881 12.0403C13.7332 12.2214 13.6366 12.3893 13.5049 12.5323V12.5323Z" fill="white"></path>
									</svg>
								</span> Iniciar sesión | Registrarse</a>
						</li>
				<!-- My Account Logged -->
				<!-- Icon User -->
				<svg id="userIconLogged" class="hidden" width="18" height="17" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.92059 6.93334C8.98246 6.93334 10.6539 5.38126 10.6539 3.46667C10.6539 1.55208 8.98246 0 6.92059 0C4.85873 0 3.18726 1.55208 3.18726 3.46667C3.18726 5.38126 4.85873 6.93334 6.92059 6.93334Z" fill="white"></path>
					<path d="M13.5049 12.5323C13.3751 12.6777 13.2126 12.7948 13.0289 12.8756C12.8451 12.9564 12.6445 12.9988 12.4412 12.9999H1.39989C1.19652 12.9992 0.995761 12.9574 0.811457 12.8776C0.627154 12.7978 0.463699 12.6817 0.332374 12.5376C0.201049 12.3934 0.104982 12.2244 0.0508024 12.0424C-0.00337724 11.8604 -0.0143789 11.6696 0.0185567 11.4833C0.20044 10.4634 0.642779 9.4988 1.30855 8.67024C1.97432 7.84168 2.84415 7.17327 3.84523 6.72095C4.69468 7.41629 5.78817 7.79995 6.92056 7.79995C8.05296 7.79995 9.14645 7.41629 9.9959 6.72095C10.997 7.17327 11.8668 7.84169 12.5326 8.67024C13.1983 9.4988 13.6407 10.4634 13.8226 11.4833C13.8547 11.6691 13.843 11.8591 13.7881 12.0403C13.7332 12.2214 13.6366 12.3893 13.5049 12.5323V12.5323Z" fill="white"></path>
				</svg>
				<span id="userDownIconLogged" class="glyphicon glyphicon-chevron-down hidden"></span>
				<li>
					<div class="nav-location hidden-xs">
								<a href="https://fraiche.com.mx/fraichestorefront/es/store-finder" class="btn">
									<span>
										<svg width="15" height="18" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M5 9.01778e-06C4.98948 0.00104325 4.97904 0.00263729 4.96869 0.0046171C2.22629 0.0200611 0 1.99821 0 4.4278C0 5.20711 0.236125 5.93426 0.636744 6.57013C0.636727 6.57161 0.636727 6.57324 0.636744 6.57472C1.9754 8.67398 3.36594 10.7743 4.73382 12.8678C4.85205 13.0462 5.19858 13.0434 5.31316 12.8632L9.31629 6.64862L9.32673 6.63473C9.3279 6.63296 9.33081 6.63192 9.33194 6.63015C9.70987 5.89601 9.99215 5.10257 10 4.4278C10 1.98891 7.7572 0 5 0L5 9.01778e-06ZM5 1.87917C6.60722 1.87917 7.89145 3.01063 7.89145 4.43243C7.89145 5.85422 6.60722 6.9903 5 6.9903C3.39279 6.9903 2.11378 5.85422 2.11378 4.43243C2.11378 3.01063 3.39279 1.87917 5 1.87917Z" fill="white"></path>
										</svg>
									</span> Encuentra tu tienda</a>
							</div>
						</li>
			</ul>
		</div><!--.container-->
	</div>

	<div class="container">
		<nav class="navigation navigation--top hidden-xs hidden-sm">
			<div class="row row-menu">
				<div class="col-sm-12 col-md-2">
					<div class="nav__left js-site-logo pull-left">
						<div class="yCmsComponent yComponentWrapper">
							<div class="banner__component simple-banner">
								<a href="https://fraiche.com.mx/fraichestorefront/es/"><img title="FRAICHE" alt="FRAICHE" src="../../assets/layout/images/FraicheLogo.svg"></a>
			</div></div></div>
				</div>
				<div class="col-sm-12 col-md-10">
					<div class="nav__right pull-right">
						<ul class="nav__links nav__links--account">
							<li id="link-catalog">
								<div class="sub-navigation-section col-md-12">
									<a class="yCmsComponent nav__link__title mt-0 mb-0">Catálogos</a>
									<ul class="sub-navigation-list ">
										<li class="yCmsComponent nav__link--secondary">
										<a href="https://perfumesfraiche.com/conten/pdfs/Catalogo_Tlalli_2024.pdf" title="Catálogo Tlalli" target="_blank" rel="noopener noreferrer">Catálogo Tlalli</a></li><li class="yCmsComponent nav__link--secondary">
										<a href="https://perfumesfraiche.com/conten/pdfs/Catalogo_Fraiche_2024.pdf" title="Catálogo Fraiche" target="_blank" rel="noopener noreferrer">Catálogo Fraiche</a></li>
										</ul>
								</div>
							</li>
							<li id="link-genealogy">
								<div class="sub-navigation-section col-md-12">
									<a class="yCmsComponent nav__link__title mt-0 mb-0">Genealogía</a>
									<ul class="sub-navigation-list ">
										<li class="yCmsComponent nav__link--secondary">
											<a href="https://fraiche.com.mx/fraichestorefront/es/Genealogia" title="Fraiche">Fraiche</a></li><li class="yCmsComponent nav__link--secondary">
											<a href="https://perfumesfraiche.com/GenealogiaNeeche/" title="Neeche Passion" target="_blank" rel="noopener noreferrer">Neeche Passion</a>
										</li>
									</ul>
								</div>
							</li>
							<li id="link-distributor">
								<div class="sub-navigation-section col-md-12">
									<a class="yCmsComponent nav__link__title mt-0 mb-0">Distribuidores Fraiche</a>
									<ul class="sub-navigation-list ">
										<li class="yCmsComponent nav__link--secondary">
											<a href="https://fraiche.com.mx/fraichestorefront/es/ConvierteteenunDistribuidor" title="Conviértete en Distribuidor">Conviértete en Distribuidor</a></li><li class="yCmsComponent nav__link--secondary">
											<a href="https://fraiche.com.mx/fraichestorefront/es/YaEresDistribuidor" title="Ya soy Distribuidor">Ya soy Distribuidor</a></li><li class="yCmsComponent nav__link--secondary">
											<a href="https://fraiche.com.mx/fraichestorefront/es/c/KIT-BIENVENIDA-DISTRIBUIDOR-INDEPENDIENT/p/000000000001014287" title="Kit Distribuidor Independiente">Kit Distribuidor Independiente</a>
										</li>
									</ul>
								</div>
							</li>
							<li id="link-franchise"><a href="https://franquiciasfraiche.com" target="_blank" title="Franquicias">Franquicias</a></li>
							<li id="link-buy"><a href="https://fraiche.com.mx/fraichestorefront/es/ComoComprar" title="¿Cómo comprar?">¿Cómo comprar?</a></li>
							<li id="link-blog"><a href="https://fraiche.com.mx/fraichestorefront/es/Blog" title="Blog">Blog</a></li>

						</ul>
					</div>
				</div>
			</div>
		</nav>
	</div>

	<p-menubar *ngIf="isMobile">
		<ng-template pTemplate="start">
			<div class="branding-mobile">
				<a href="https://fraiche.com.mx/fraichestorefront/es/">
				<img title="FRAICHE" alt="FRAICHE" src="../../assets/layout/images/FraicheLogo.svg">
				</a>
			</div>
		</ng-template>		
		<ng-template pTemplate="end">
			<button type="button" class="nav-mobile-btn" pButton (click)="toggleSidebar()">
				<span>
					<svg fill="#08c4ce" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30px" height="30px"><path d="M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z"></path></svg>
				</span>
			</button>
		</ng-template>
	</p-menubar>

<p-sidebar [(visible)]="sidebarVisible" [baseZIndex]="10000">
  <ul class="p-list p-component">
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/HOTSALE-2024/c/HOTSALE2024B2C">Ofertas</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/Perfumer%C3%ADa/c/PerfumeriaB2C">Perfumería</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/Cuidado-Personal/c/CuidadoPersonalB2C">Cuidado Personal</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/Cosm%C3%A9ticos/c/CosmeticosB2C">Cosméticos</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/Ambientales-y-Velas/c/AmbientalesyVelasB2C">Ambientales y Velas</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/Aromaterapia/c/AromaterapiaB2C">Aromaterapia</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/Sets-de-regalo/c/SetsderegaloB2C">Sets de regalo</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/Ediciones-Especiales/c/EdicionesEspecialesB2C">Ediciones Especiales</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/Mascotas/c/Mascotas">Mascotas</a></li>
    <li><a href="https://fraiche.com.mx/fraichestorefront/es/NeechePassion/c/Linea_NeechePassion_categoria">NEECHE PASSION</a></li>
  </ul>
</p-sidebar>

	
	<nav class="navigation navigation--bottom js_navigation--bottom js-enquire-offcanvas-navigation" role="navigation">
		<div class="navigation__overflow">
			<ul class="nav__links nav__links--products js-offcanvas-links">
				<li class="auto nav__links--primary ">
					<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/HOTSALE-2024/c/HOTSALE2024B2C" title="Ofertas">Ofertas</a></span></li>
				<li class="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub">
					<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/Perfumer%C3%ADa/c/PerfumeriaB2C" title="Perfumería">Perfumería</a></span><span class="glyphicon  glyphicon-chevron-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down"></span>						
				</li>
				<li class="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub">
					<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/Cuidado-Personal/c/CuidadoPersonalB2C" title="Cuidado Personal">Cuidado Personal</a></span>								
				</li>
				<li class="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub">
					<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/Cosm%C3%A9ticos/c/CosmeticosB2C" title="Cosméticos">Cosméticos</a></span><span class="glyphicon  glyphicon-chevron-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down"></span>
				</li>
				<li class="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub">
					<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/Ambientales-y-Velas/c/AmbientalesyVelasB2C" title="Ambientales y Velas">Ambientales y Velas</a></span><span class="glyphicon  glyphicon-chevron-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down"></span>
				</li>
				<li class="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub">
					<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/Aromaterapia/c/AromaterapiaB2C" title="Aromaterapia">Aromaterapia</a></span><span class="glyphicon  glyphicon-chevron-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down"></span>
				</li>
				<li class="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub">
						<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/Sets-de-regalo/c/SetsderegaloB2C" title="Sets de regalo">Sets de regalo</a></span><span class="glyphicon  glyphicon-chevron-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down"></span>
					</li>
				<li class="auto nav__links--primary nav__links--primary-has__sub js-enquire-has-sub">
						<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/Ediciones-Especiales/c/EdicionesEspecialesB2C" title="Ediciones Especiales">Ediciones Especiales</a></span><span class="glyphicon  glyphicon-chevron-right hidden-md hidden-lg nav__link--drill__down js_nav__link--drill__down"></span>
				</li>
				<li class="auto nav__links--primary ">
					<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/Mascotas/c/Mascotas" title="Mascotas">Mascotas</a></span>
				</li>
				<li class="auto nav__links--primary ">
						<span class="yCmsComponent nav__link js_nav__link"><a href="https://fraiche.com.mx/fraichestorefront/es/NeechePassion/c/Linea_NeechePassion_categoria" title="NEECHE PASSION">NEECHE PASSION</a></span>
				</li>
			</ul>
		</div>
	</nav>
</header>