import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mensaje-franquicia',
  templateUrl: './mensaje-franquicia.component.html',
  styleUrl: './mensaje-franquicia.component.scss'
})
export class MensajeFranquiciaComponent {

  constructor(private router: Router) { }

  goToHome() {
    this.router.navigate(['/']);
  }
}
