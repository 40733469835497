<div class="page-container">


    <app-header></app-header>

    <form class="layout-container" action="" method="post">
        <div class="field pt-4" id="divFact">
            <app-containe-factura></app-containe-factura>
        </div>
    </form>
    
    <app-footer></app-footer>


</div>