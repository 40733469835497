import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { MenuItemCommandEvent } from 'primeng/api/menuitem';
import { loginRequest, loginResponse } from '../core/Interfaces/login';
import { FacturacionService } from '../core/services/facturacion.service';
import { TokenService } from '../core/services/token.service';
import packageJson from '../../../package.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class homeComponent implements OnInit {
  public version: string = packageJson.version;
  loginResquest: loginRequest | undefined;
  loginResponse!: loginResponse;

  items: MenuItem[] | undefined;
  event: MenuItemCommandEvent | undefined;
  bFactura: boolean = false;
  bReFactura: boolean = false;
  token!: TokenService;
  // selectedMenu: MenuItem | undefined;

  constructor(private service: FacturacionService, token: TokenService) {
    this.token = token;
  }

  async ngOnInit() {
    this.items = [
      {
        label: 'Factura Ticket',
        icon: 'pi pi-fw pi-file',
        command: (event) => {
          this.activateMenu(event);
        },
      },
    ];

    await this.Login();
  }

  async Login() {
    this.loginResquest = {
      Email: 'cperez',
      Password: 'qa',
      PuntoVenta: 'F000',
    };

    let data = await this.service.login(this.loginResquest);
    this.loginResponse = data;
    this.token.saveToken(this.loginResponse.Data.IdToken);
  }

  activateMenu(event: MenuItemCommandEvent) {
    let itemMenu = event.item?.label;

    this.bFactura = false;
    this.bReFactura = false;

    switch (itemMenu) {
      case 'Factura Ticket': {
        this.bFactura = true;
        break;
      }

      case 'Descargar Facturas': {
        this.bReFactura = true;
        break;
      }
    }
  }
}
