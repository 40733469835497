import {  NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { homeComponent} from './components/home.component';
import {AppComponent} from './app.component'
import { InstructionsComponent } from './components/instructions/instructions.component';
import { MensajeFranquiciaComponent } from './components/mensaje-franquicia/mensaje-franquicia.component';
import { NotFoundComponent } from './components/not-found/not-found.component';



const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'instructions' },
  { path: 'instructions', component: InstructionsComponent },
  { path: 'home', component:  homeComponent} ,
  { path: 'mensaje-franquicia', component: MensajeFranquiciaComponent },
  { path: '**', component: NotFoundComponent}
  //{ path: '', pathMatch: 'full', redirectTo: 'home' },



];

@NgModule({
  declarations: [
    AppComponent
    
  ],
  imports: [RouterModule.forRoot(routes)],

})


export class AppRoutingModule { }


/*
.forRoot([
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component:  homeComponent},

])



path: '',
component: homeComponent,
children: [
    
]
*/