<footer>
	<div class="footer__top">
		<div class="container">
			<div class="row">
				<div class="footer__left col-xs-12 col-sm-12 col-md-8 col-lg-9">
					<div class="row visible-xs">
						<div class="col-xs-12 col-sm-6">
							<ul class="list-unstyled list-inline" id="icons-social-footer">
								<li class="list-inline-item">
									<a href="//www.instagram.com/fraiche.mx" target="_blank" title="Instagram ©">
										<i>
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
												<rect width="30" height="30" fill="url(#pattern1)"></rect>
												<defs>
													<pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
														<use xlink:href="#image0_103_140" transform="translate(-0.0125) scale(0.025)"></use>
													</pattern>
													<image id="image0_103_140" width="41" height="40" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAADNElEQVRYhdVZW0tUURhdn7dGkzIDES0i6SVNfDCIoOxBkSC6IGgvIQSa+ReKeqqQgp4T66WewyCIEsKEIIKISDIIkaKQiCALzRu54ovvyOk4M2fPnBlnXC97ON9lrX3Ovnx7jyBNkNwJoBXAEQD1AOoAbANQCOAPgB8ApgBMABgD8FREPqfL5wySRSTPkHxGcoWpYcXiNL4oWwI7SU6mKCwRNE9XJsVVk3ycIXFBaN7qqAJbSH7NkkAPmr8lXYEnSM5nWaAH5TmZqsB2kkvrJNCD8rXH07NmCSLZAOAlgM1pfYJomANwQETeJRRJMgbgFYCGHAj0oOtqs4gseA+C69UlR4GLAEasQ78c/LcA2A9AP+emEN9603FxjYVkHclFh7EzTLLWQVg8jlqLD4PqqPPixJfgFoC+EJ4h9RER5WgEcBxAlYO+bwAeisg4SeUcBNAbEjMoIuf9Pax0eIvvSRab/0Ca2+KAxRdbvmRQPZV+kf0OJN3m25OiOEUvyVaSP/W35el2iOv3i3ziEFBlvh+S+HwiOUryje9NL3gFBcnnGm+/qxw4RzyBJSR/hzjPm28sgf01yYP+AWWT5K7ZH5Ec8vnHzCeMV+0l2sO9AEpDBvGitbE4thcA2gDM247RDOALgPsiop9Ua8gLgRjNo+vgUgh36T99VoKFYcZ6XhHwWya5h2RhnKVliuQukgUkxwO2Css348DdWQCgJuQtJsOoiEwCOAvgVMBvN4CbIrIC4HYEjhoVWR4hwVtr2xLYvYJhIgJHeUGEYEWZtbMJ7N6WWRKFpCAJgQsOmc+9BL7e88MROGZV5HSEBI0kj4mIngZ7fG+OAO4AuExyO4BzETimdYY1RZjdiu+aw+ylJPd52xnJrSTH4sSkMrubMrWYz5G8okUHyTKSO0j2kfyYwD+lxRzI7LbogpS3RW92P3AYG0etvR5hfCluBPIlw/CqLUel2kSI/2qptnGKXmyU44MluRqnYomHbB7EFNdEZPUgtiGOtP/t3WY4bYf0XEB5u/wC14g0oXp70AFgeZ1FKl9H8PYiKfL+wspD3l/9+YTm9yVqQGz+XkcHhObkYj///yIB8BduHm0/cembagAAAABJRU5ErkJggg=="></image>
												</defs>
											</svg>
										</i>
									</a>
								</li>
								<li class="list-inline-item">
									<a href="//www.facebook.com/FraicheMexico" target="_blank" title="Facebook ©">
										<i>
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
												<rect width="30" height="30" fill="url(#pattern0facemobile)"></rect>
												<defs>
													<pattern id="pattern0facemobile" patternContentUnits="objectBoundingBox" width="1" height="1">
														<use xlink:href="#image0_402_161" transform="scale(0.025)"></use>
													</pattern>
													<image id="image0_402_161" width="40" height="40" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAACO0lEQVRYhdWZPWtUQRSGn7MmGKJiCklUiDYRxMqvVCpI9AfYWWgpWFj4F8QfIAgqCGIhMUgqG+0MCmq3RmyMYDCpTExYZPELEnlllrlyHTZk987d3fGp9t7dPfPMndnDmbNGAST1A6eACeAocBAYAQZ9tO/AEvAeqAIzwGszWy8yXstIGpN0S9Kq2mdJ0g1J+zohtlfSQ0nrBcRC1iTdkzRcltxlSfUSxEJqki7EiA1ImuqAWMhdv6fbktsp6XkX5DKeShpsQa0ht7XLchlPJPW1IjjZA7mMO5vJXeqhXMb5vJPl5PYAH4AdLe2F5tSBF0At9+6oT+itsuISv5nV/vm8pAeRM592P64mq3K6QKzbYZCxyCT8bqNUUVDwp19RKj7OVWBLxNLeN7O1iO+HDABXshn2S1qJeHqOc/kBJJ2UVJX0VtJiwZgLkszlnRPArhJn7zjrq5wY9gNH3BKfKVmuTCb6Imd6HfgEvAnuPwYW/Ovjf/dT+xxz+2U+Yu8d3mxISRcj4s9WfCXcSXZHxB5xS7wtIsCopK/AFzP7kXtqQ8CQvzwQEX87EY8/T5hmrpUUt17xB5xU+eYElxMWXHaCcwmIbMRcpUkOS4mqE3yWsOCME3wFrCYgE7IIzFZ8mfQoLbcGk2amrB68CfzusVCeX0Cjqm4ImtlHYCoVO18Af6bEQ9NLYD53PQ4cKijX/NBEosfOZpLpHtz5H1ofpN48ykmm234LRNNsYAaS6baAA9GuN9HT/hsC+AO1/6Gw49WsDgAAAABJRU5ErkJggg=="></image>
												</defs>
											</svg>
										</i>
									</a>
								</li>
								<li class="list-inline-item">
									<a href="//www.youtube.com/channel/UC-ezheRgrhvR7n5kRERQLzw/featured" target="_blank" title="YouTube ©">
										<svg fill="#FFFFFF" width="30" height="30" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-143 145 512 512" xml:space="preserve">
											<g>
												<polygon points="78.9,450.3 162.7,401.1 78.9,351.9 	"></polygon>
												<path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M241,446.8L241,446.8
													c0,44.1-44.1,44.1-44.1,44.1H29.1c-44.1,0-44.1-44.1-44.1-44.1v-91.5c0-44.1,44.1-44.1,44.1-44.1h167.8c44.1,0,44.1,44.1,44.1,44.1
													V446.8z"></path>
											</g>
										</svg>
									</a>
								</li>
								<li class="list-inline-item">
									<a href="//www.tiktok.com/@fraicheoficial" target="_blank" title="TikTok ©">
										<svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" width="30" height="30" viewBox="0 0 3333 3333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
											<path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm361 744c31 262 177 418 430 434v294c-147 14-276-34-426-124v550c0 700-763 918-1069 417-197-322-76-889 556-911v311c-48 8-99 20-146 36-141 47-220 137-198 294 43 301 595 390 549-198V745h305z"></path>
										</svg>
									</a>
								</li>
							</ul>
						</div>
						<div class="hidden-xs col-sm-6" id="social_copyright_tablet">
							<p class="title"><a href="https://fraiche.com.mx/fraichestorefront/es/">fraiche.com.mx</a></p>
							<p class="title title-copyright">© Fraiche,&nbsp;<span id="currentYearTablet">2024</span> | Todos los derechos reservados | Salud es Belleza&nbsp;COFEPRIS&nbsp;<span class="number">123300</span>EL<span class="number">950986</span></p>
						</div>
					</div>
					
					<div class="row">
						<div class="footer__nav--container col-xs-12 col-sm-3 hidden-xs">
							<h4 class="title visible-xs">FRAICHE</h4>
							<ul class="footer__nav--links">
								<li class="yCmsComponent footer__link">
								<a href="https://fraiche.com.mx/fraichestorefront/es/AcercadeNosotros" title="Acerca de Nosotros">Acerca de Nosotros</a></li><li class="yCmsComponent footer__link">
								<a href="https://fraiche.com.mx/fraichestorefront/es/Marcas" title="Marcas">Marcas</a></li><li class="yCmsComponent footer__link">
								<a href="https://fraiche.com.mx/fraichestorefront/es/Promociones" title="Promociones">Promociones</a></li><li class="yCmsComponent footer__link">
								<a href="https://fraiche.com.mx/fraichestorefront/es/Contactanos" title="Contáctanos">Contáctanos</a></li>
							</ul>
						</div>

						<div class="footer__nav--container col-xs-12 col-sm-3 hidden-xs">
							<h4 class="title visible-xs">INFORMACIÓN FRAICHE</h4>
							<ul class="footer__nav--links">
								<li class="yCmsComponent footer__link">
									<a href="https://fraiche.com.mx/fraichestorefront/es/Aviso%20de%20privacidad" title="Aviso de privacidad">Aviso de privacidad</a></li><li class="yCmsComponent footer__link">
									<a href="https://fraiche.com.mx/fraichestorefront/es/PoliticadePrivacidadySeguridad" title="Política&nbsp;de Privacidad y Seguridad">Política&nbsp;de Privacidad y Seguridad</a></li><li class="yCmsComponent footer__link">
									<a href="https://fraiche.com.mx/fraichestorefront/es/PoliticadeEnvioyDevolucion&nbsp;" title="Política&nbsp;de Envío y Devolución&nbsp;">Política&nbsp;de Envío y Devolución&nbsp;</a></li><li class="yCmsComponent footer__link">
									<a href="https://fraiche.com.mx/fraichestorefront/TermsAndConditions1" title="Términos&nbsp;y Condiciones">Términos&nbsp;y Condiciones</a></li>
							</ul>
						</div>
								
						<div class="footer__nav--container col-xs-12 col-sm-3 hidden-xs">
							<h4 class="title visible-xs">DISTRIBUIDOR</h4>
							<ul class="footer__nav--links">
								<li class="yCmsComponent footer__link">
									<a href="https://fraiche.com.mx/fraichestorefront/es/ConvierteteenunDistribuidor" title="Conviértete&nbsp;en un Distribuidor">Conviértete&nbsp;en un Distribuidor</a></li><li class="yCmsComponent footer__link">
									<a href="https://fraiche.com.mx/fraichestorefront/es/YaEresDistribuidor" title="Ya eres Distribuidor">Ya eres Distribuidor</a></li><li class="yCmsComponent footer__link">
									<a href="https://fraiche.com.mx/fraichestorefront/es/PoliticadeDistribuidores" title="Política&nbsp;de Distribuidores">Política&nbsp;de Distribuidores</a></li><li class="yCmsComponent footer__link">
									<a onmouseover="this.style.textDecoration='underline';" (click)="showFAQDialog()" role="button" title="Preguntas Frecuentes">Preguntas Frecuentes</a></li>
							</ul>
						</div>

						<div class="footer__nav--container col-xs-12 col-sm-3 visible-xs">
							<p-accordion 
							class="w-full"
							expandIcon="pi pi-plus" 
							collapseIcon="pi pi-minus">
								<p-accordionTab header="FRAICHE">
									<ul class="">
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/AcercadeNosotros" title="Acerca de Nosotros">Acerca de Nosotros</a></li>
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/Marcas" title="Marcas">Marcas</a></li>
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/Promociones" title="Promociones">Promociones</a></li>
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/Contactanos" title="Contáctanos">Contáctanos</a></li>
									</ul>
								</p-accordionTab>
								<p-accordionTab header="INFORMACIÓN FRAICHE">
									<ul class="">
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/Aviso%20de%20privacidad" title="Aviso de privacidad">Aviso de privacidad</a></li>
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/PoliticadePrivacidadySeguridad" title="Política&nbsp;de Privacidad y Seguridad">Política&nbsp;de Privacidad y Seguridad</a></li>
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/PoliticadeEnvioyDevolucion&nbsp;" title="Política&nbsp;de Envío y Devolución&nbsp;">Política&nbsp;de Envío y Devolución&nbsp;</a></li>
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/TermsAndConditions1" title="Términos&nbsp;y Condiciones">Términos&nbsp;y Condiciones</a></li>
									</ul>
								</p-accordionTab>
								<p-accordionTab header="DISTRIBUIDOR">
									<ul class="">
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/ConvierteteenunDistribuidor" title="Conviértete&nbsp;en un Distribuidor">Conviértete&nbsp;en un Distribuidor</a></li>
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/YaEresDistribuidor" title="Ya eres Distribuidor">Ya eres Distribuidor</a></li>
										<li class=""><a href="https://fraiche.com.mx/fraichestorefront/es/PoliticadeDistribuidores" title="Política&nbsp;de Distribuidores">Política&nbsp;de Distribuidores</a></li>
										<li class=""><a onmouseover="this.style.textDecoration='underline';" (click)="showFAQDialog()" role="button" title="Preguntas Frecuentes">Preguntas Frecuentes</a></li></ul>
								</p-accordionTab>
							</p-accordion>
						</div>
								
					</div><!--.row-->
					<div class="visible-xs" id="social_copyright_mobile">
						<p class="title"><a href="https://fraiche.com.mx/fraichestorefront/es/">fraiche.com.mx</a></p>
						<p class="title title-copyright">© Fraiche,&nbsp;<span id="currentYearMobile">2024</span> | Todos los derechos reservados | Salud es Belleza&nbsp;COFEPRIS&nbsp;<span class="number">123300</span>EL<span class="number">950986</span></p>
					</div>
				</div>

				<div class="footer__right col-xs-12 col-sm-12 col-md-4 col-lg-3 hidden-xs">
					<div class="row">
						<div class="col-xs-12" id="social_copyright">
							<p class="title hidden-sm"><a href="https://fraiche.com.mx/fraichestorefront/es/">fraiche.com.mx</a></p>
							<ul class="list-unstyled list-inline" id="icons-social-footer">
								<li class="list-inline-item">
									<a href="//www.instagram.com/fraiche.mx" target="_blank" title="Instagram ©">
										<i>
											<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
												<rect width="40" height="40" fill="url(#pattern0)"></rect>
												<defs>
													<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
														<use xlink:href="#image0_103_140" transform="translate(-0.0125) scale(0.025)"></use>
													</pattern>
													<image id="image0_103_140" width="41" height="40" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAADNElEQVRYhdVZW0tUURhdn7dGkzIDES0i6SVNfDCIoOxBkSC6IGgvIQSa+ReKeqqQgp4T66WewyCIEsKEIIKISDIIkaKQiCALzRu54ovvyOk4M2fPnBlnXC97ON9lrX3Ovnx7jyBNkNwJoBXAEQD1AOoAbANQCOAPgB8ApgBMABgD8FREPqfL5wySRSTPkHxGcoWpYcXiNL4oWwI7SU6mKCwRNE9XJsVVk3ycIXFBaN7qqAJbSH7NkkAPmr8lXYEnSM5nWaAH5TmZqsB2kkvrJNCD8rXH07NmCSLZAOAlgM1pfYJomANwQETeJRRJMgbgFYCGHAj0oOtqs4gseA+C69UlR4GLAEasQ78c/LcA2A9AP+emEN9603FxjYVkHclFh7EzTLLWQVg8jlqLD4PqqPPixJfgFoC+EJ4h9RER5WgEcBxAlYO+bwAeisg4SeUcBNAbEjMoIuf9Pax0eIvvSRab/0Ca2+KAxRdbvmRQPZV+kf0OJN3m25OiOEUvyVaSP/W35el2iOv3i3ziEFBlvh+S+HwiOUryje9NL3gFBcnnGm+/qxw4RzyBJSR/hzjPm28sgf01yYP+AWWT5K7ZH5Ec8vnHzCeMV+0l2sO9AEpDBvGitbE4thcA2gDM247RDOALgPsiop9Ua8gLgRjNo+vgUgh36T99VoKFYcZ6XhHwWya5h2RhnKVliuQukgUkxwO2Css348DdWQCgJuQtJsOoiEwCOAvgVMBvN4CbIrIC4HYEjhoVWR4hwVtr2xLYvYJhIgJHeUGEYEWZtbMJ7N6WWRKFpCAJgQsOmc+9BL7e88MROGZV5HSEBI0kj4mIngZ7fG+OAO4AuExyO4BzETimdYY1RZjdiu+aw+ylJPd52xnJrSTH4sSkMrubMrWYz5G8okUHyTKSO0j2kfyYwD+lxRzI7LbogpS3RW92P3AYG0etvR5hfCluBPIlw/CqLUel2kSI/2qptnGKXmyU44MluRqnYomHbB7EFNdEZPUgtiGOtP/t3WY4bYf0XEB5u/wC14g0oXp70AFgeZ1FKl9H8PYiKfL+wspD3l/9+YTm9yVqQGz+XkcHhObkYj///yIB8BduHm0/cembagAAAABJRU5ErkJggg=="></image>
												</defs>
											</svg>
										</i>
									</a>
								</li>
								<li class="list-inline-item">
									<a href="//www.facebook.com/FraicheMexico" target="_blank" title="Facebook ©">
										<i>
											<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
												<rect width="40" height="40" fill="url(#pattern0face)"></rect>
												<defs>
													<pattern id="pattern0face" patternContentUnits="objectBoundingBox" width="1" height="1">
														<use xlink:href="#image0_402_161" transform="scale(0.025)"></use>
													</pattern>
													<image id="image0_402_161" width="40" height="40" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAACO0lEQVRYhdWZPWtUQRSGn7MmGKJiCklUiDYRxMqvVCpI9AfYWWgpWFj4F8QfIAgqCGIhMUgqG+0MCmq3RmyMYDCpTExYZPELEnlllrlyHTZk987d3fGp9t7dPfPMndnDmbNGAST1A6eACeAocBAYAQZ9tO/AEvAeqAIzwGszWy8yXstIGpN0S9Kq2mdJ0g1J+zohtlfSQ0nrBcRC1iTdkzRcltxlSfUSxEJqki7EiA1ImuqAWMhdv6fbktsp6XkX5DKeShpsQa0ht7XLchlPJPW1IjjZA7mMO5vJXeqhXMb5vJPl5PYAH4AdLe2F5tSBF0At9+6oT+itsuISv5nV/vm8pAeRM592P64mq3K6QKzbYZCxyCT8bqNUUVDwp19RKj7OVWBLxNLeN7O1iO+HDABXshn2S1qJeHqOc/kBJJ2UVJX0VtJiwZgLkszlnRPArhJn7zjrq5wY9gNH3BKfKVmuTCb6Imd6HfgEvAnuPwYW/Ovjf/dT+xxz+2U+Yu8d3mxISRcj4s9WfCXcSXZHxB5xS7wtIsCopK/AFzP7kXtqQ8CQvzwQEX87EY8/T5hmrpUUt17xB5xU+eYElxMWXHaCcwmIbMRcpUkOS4mqE3yWsOCME3wFrCYgE7IIzFZ8mfQoLbcGk2amrB68CfzusVCeX0Cjqm4ImtlHYCoVO18Af6bEQ9NLYD53PQ4cKijX/NBEosfOZpLpHtz5H1ofpN48ykmm234LRNNsYAaS6baAA9GuN9HT/hsC+AO1/6Gw49WsDgAAAABJRU5ErkJggg=="></image>
												</defs>
											</svg>
										</i>
									</a>
								</li>
								<li class="list-inline-item">
									<a href="//www.youtube.com/channel/UC-ezheRgrhvR7n5kRERQLzw/featured" target="_blank" title="YouTube ©">
										<svg fill="#FFFFFF" width="40" height="40" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-143 145 512 512" xml:space="preserve">
											<g>
												<polygon points="78.9,450.3 162.7,401.1 78.9,351.9 	"></polygon>
												<path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M241,446.8L241,446.8
													c0,44.1-44.1,44.1-44.1,44.1H29.1c-44.1,0-44.1-44.1-44.1-44.1v-91.5c0-44.1,44.1-44.1,44.1-44.1h167.8c44.1,0,44.1,44.1,44.1,44.1
													V446.8z"></path>
											</g>
										</svg>
									</a>
								</li>
								<li class="list-inline-item">
									<a href="//www.tiktok.com/@fraicheoficial" target="_blank" title="TikTok ©">
										<svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" width="40" height="40" viewBox="0 0 3333 3333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
											<path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm361 744c31 262 177 418 430 434v294c-147 14-276-34-426-124v550c0 700-763 918-1069 417-197-322-76-889 556-911v311c-48 8-99 20-146 36-141 47-220 137-198 294 43 301 595 390 549-198V745h305z"></path>
										</svg>
									</a>
								</li>
							</ul>
							<p class="title title-copyright">© Fraiche,&nbsp;<span id="currentYear">2024</span> | Todos los derechos reservados | Salud es Belleza&nbsp;COFEPRIS&nbsp;<span class="number">123300</span>EL<span class="number">950986</span></p>
						</div>
					</div>
				</div>
			</div>
		</div><!--.container-->
	</div>
</footer>

<p-dialog
  class="faq-modal"
  header="Preguntas frecuentes"
  [modal]="true"
  [(visible)]="display_faq_modal"
  position="topright" 
  [style]="{ width: '25rem' }"
  [draggable]="false"
  [dismissableMask]="true"
>
  <p-accordion [activeIndex]="0">
    <p-accordionTab header="1. ¿Si no cuento con RFC puedo facturar?">
      <p>
        Si, puedes facturar a tu nombre utilizando un RFC genérico nacional o extranjero.<br />
        RFC genérico nacional XAXX010101000<br />
        RFC genérico extranjero XEXX010101000
      </p>
    </p-accordionTab>
    <p-accordionTab header="2. Errores de captura más frecuentes">
      <p>
        <strong>-Error de datos fiscales:</strong> Asegúrate de capturar la razón social tal y como viene en tu cédula de situación fiscal evitando abreviaciones, puntos, comas, acentos o números. <strong>Ejemplos.-</strong><br />
        <strong>Correcto:</strong> Maria Cristina Rosales Solorzano<br />
        <strong><span style="color: #e74c3c">Incorrecto: </span></strong>
        Ma. Christina Rosales Solórzano (el error es: No se aceptan abreviaciones, una “h” más y una letra contiene un acento).<br />
        <strong>Correcto:</strong> Tienda Fraiche<br />
        <strong><span style="color: #e74c3c">Incorrecto:</span></strong>
        Tienda Fraiche, S.A. de C.V. (el error es que se incluyó el régimen de capital) 
        <strong>-RFC sin homoclave: </strong>El RFC (Registro federal de contribuyentes) es un código alfanumérico conformado por 13 dígitos incluyendo la homoclave XXXX000000XXX, no agregues los guiones.<br />
        <strong>-Código postal incompleto:</strong> Cualquier código postal se conforma por un total de 5 caracteres numéricos mismos que deberán ser rellenados con “0” en caso que tu código postal contenga menos dígitos.
      </p>
    </p-accordionTab>
    <p-accordionTab header="3. ¿Qué debo seleccionar en la opción del uso CFDI?">
      <p>
        El uso del CFDI es la opción que mejor describa la utilidad que le darás a la factura para tus deducciones.<br />
        <br />
        Se muestran sólo algunas de donde podrás seleccionar:<br />
        <br />
        P01 Por definir.<br />
        G01 Adquisición de mercancías<br />
        G01 Devoluciones, descuentos o bonificaciones.<br />
        I01 Construcciones<br />
        I02 Mobiliario y equipo de oficinas por inversiones<br />
        I03 Equipo de transporte<br />
        I04 Equipo de cómputo y accesorios
      </p>
    </p-accordionTab>
    <p-accordionTab header="4. Si no me llegó mi factura por correo electrónico ¿Qué debo de hacer?">
      <p>
        Inicia sesión en la página www.Fraiche.com, ingresa en la sección <strong>“Consulta facturas”,</strong> ahí encontrarás el historial de tus facturas del año fiscal en curso y podrás descargar tus archivos en formato PDF y XML.
      </p>
    </p-accordionTab>
    <p-accordionTab header="5. ¿La fecha de emisión de factura es en la que realicé mi compra?">
      <p>
        No, la fecha en que generes tu factura es la fecha que aparecerá. Pero en la sección inferior de tu factura, aparece la fecha en que realizaste tu compra.<br />
        <br />
        Lo podrás localizar como:
        <strong>Venta del día: dd/mm/aaaa</strong>
      </p>
    </p-accordionTab>
    <p-accordionTab header="6. ¿Qué pasa si no facturo dentro de los 30 días?">
      <p>
        Podrás facturar hasta 70 días naturales a partir de la fecha en que realizaste tu compra. Pasados estos días, ya no podrás facturar, por lo que te recomendamos facturar antes de este plazo termine.
      </p>
    </p-accordionTab>
    <p-accordionTab header="7. ¿Puedo modificar mis facturas emitidas?">
      <p>
        Si es posible modificar tu factura, para ello, tendrás que cancelarla primero, una vez liberada la cancelación de parte del SAT, podrás volver a facturar tu compra.
      </p>
    </p-accordionTab>
    <p-accordionTab header="8. ¿Cuánto tiempo tengo para cancelar mi factura?">
      <p>
        Puedes cancelar tus facturas del ejercicio fiscal vigente hasta el 31 de marzo del año 2024. Una vez que las cancelas, deberás de esperar un periodo de 3 días hábiles para el SAT refleje la cancelación, podrás consultarlo en tu portal del SAT.
      </p>
    </p-accordionTab>
    <p-accordionTab header="9. ¿Cómo cancelo mi factura?">
      <p>
        Ingresa a la sección <strong>“Consulta tus facturas”</strong>, selecciona el rango de fechas en que facturaste y escribe tu RFC, ubica la factura que deseas cancelar y da “clic” en el botón “Cancelar”, confirma tu solicitud y revisa tu buzón tributario para saber el proceso de tu cancelación, puede demorar hasta 3 días hábiles.
      </p>
    </p-accordionTab>
    <p-accordionTab header="10. ¿Cómo puedo consultar/descargar mis facturas emitidas?">
      <p>
        Ahora con tu cuenta en www.cityclub.com puedes imprimir, consultar y descargar todas tus facturas desde la sección de “Mis facturas”, los archivos PDF Y XML estarán disponibles hasta 1 año desde la primera emisión de tu factura.
      </p>
    </p-accordionTab>
  </p-accordion>
</p-dialog>
