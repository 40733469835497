import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrl: './instructions.component.scss'
})
export class InstructionsComponent {

  constructor(
    private router: Router) { }

display_faq_modal: boolean = false;
display_flinea_modal: boolean = false;
display_sat_modal: boolean = false;

goToHome() {
    this.router.navigate(['/home']);
}

showDialog(modal: string) {
    if (modal == 'flinea-modal'){
        this.display_flinea_modal = true;
    }
    if(modal == 'faq-modal'){
        this.display_faq_modal = true;
    }   
    if(modal == 'sat-modal'){
        this.display_sat_modal = true;
    }
}


}
