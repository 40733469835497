<form class="form-horizontal">
    <p-toast />

    <!-- <div class="field col-12 md:col-12">
        <p-messages [(value)]="messages" [enableService]="false" [closable]="true" />
    </div> -->

    <p-stepper (activeStepChange)="changeStep($event)" [activeStep]="stepStepper" [linear]="true">
        <p-stepperPanel header="Nota">
            <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                <div class="flex flex-column">
                    <div class="title w-100 text-center">
                        <h2 class="">Factura electrónica</h2>
                    </div>
                    <div class="nota-container surface-border border-round font-medium">
                        <app-data-nota (onHide)="changeStatusNext($event)"></app-data-nota>
                    </div>
                </div>
                <div class="flex pt-4 justify-content-center">
                    <p-button [disabled]="enableNext" class="next-btn" label="Siguiente" icon="right"
                        iconPos="right" (onClick)=" NextStep(nextCallback);" />
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="Datos Cliente">
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                let-index="index">
                <div class="flex flex-column ">
                    <div class="surface-border border-round font-medium">
                        <app-data-rfc [datosNotasRequest]="datosNota" [ClienteSustituto]="iTipoClienteSustituto"
                            (datosClienteRFCEmit)="getDataRFC($event)" class="flex justify-content-center flex-wrap"
                            (onHide)="changeStatusNext_1($event)" [iRFC]="selectedFRC"
                            [DatosNotasResponse]="DatosNotasResponse">
                        </app-data-rfc>
                    </div>
                </div>
                <div class="flex pt-4 justify-content-center">
                    <!-- <p-button label="Regresar" icon="pi pi-arrow-left" (onClick)=" backStep(prevCallback) " id="jump" /> -->
                    <p-button label="Generar Factura"  class="next-btn" 
                        (onClick)="NextFinal(nextCallback) " />
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="CFDI">
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                let-index="index">
                <div class="flex flex-column">
                    <!-- <div class="title w-100 text-center">
                        <h2 class="">Factura generada exitosamente</h2>
                    </div> -->
                    <div class="surface-border justify-content-center mx-auto">
                        <app-cfdi-download [bFran]="this.bFran" [sRFC]="sRFC"
                            [facturaFile]="facturaFile"></app-cfdi-download>
                    </div>
                </div>
                <div class="flex pt-4 justify-content-center">
                    <p-button label="Generar Nueva Factura" icon="pi pi-arrow-left"
                        onClick="window.location.reload()" />
                </div>
            </ng-template>
        </p-stepperPanel>
    </p-stepper>

    <div class="progress-spinner" *ngIf="displayWait">
        <p-progressSpinner></p-progressSpinner>
    </div>

</form>