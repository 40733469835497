import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ButtonModule} from 'primeng/button';
import {StyleClassModule }  from 'primeng/styleclass' 
import {homeComponent} from './components/home.component'
import {sustitutos} from  './components/container-factura/sustitutos';
import {SustitutosList} from  './components/container-factura/sustitutoslist';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SidebarModule } from 'primeng/sidebar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepperModule } from 'primeng/stepper';
import { ContainerFactura } from './components/container-factura/container-factura.component';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { DataNotaComponent } from './components/data-nota/data-nota.component';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { DataRFCComponent } from './components/data-rfc/data-rfc.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { ProductService } from '../app/demo/service/productservice';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CfdiDownloadComponent } from './components/cfdi-download/cfdi-download.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpHandler, provideHttpClient } from '@angular/common/http';
import { HttpErrorHandler } from './core/services/http-error-handler.service';
import { MessageServiceS } from './core/services/message.serviceS';
import { AuthInterceptorService } from './core/auth-interceptor.service';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { DividerModule } from 'primeng/divider';
import { FooterComponent } from './components/footer/footer.component';
import { AccordionModule } from 'primeng/accordion';
import { HeaderComponent } from './components/header/header.component';
import { MenubarModule } from 'primeng/menubar';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { MensajeFranquiciaComponent } from './components/mensaje-franquicia/mensaje-franquicia.component';
import { CheckboxModule } from 'primeng/checkbox';



@NgModule({
  declarations: [
    homeComponent,
    ContainerFactura,
    DataNotaComponent,
    DataRFCComponent,
    sustitutos,SustitutosList, CfdiDownloadComponent, FooterComponent, HeaderComponent, NotFoundComponent, InstructionsComponent, MensajeFranquiciaComponent,
    
  ],
  imports: [
    RouterModule,ToastModule,MessagesModule,AccordionModule,CheckboxModule,
    BrowserModule, ReactiveFormsModule,RouterModule,DividerModule,
    BrowserAnimationsModule,MenubarModule,
    ButtonModule,
    AppRoutingModule,
    StyleClassModule,
    CommonModule, 
    FormsModule, 
    SidebarModule,
    RadioButtonModule,
    InputSwitchModule,
    StepperModule,
    PanelModule,DialogModule,ProgressSpinnerModule,
    HttpClientModule,
    CardModule,FloatLabelModule,CalendarModule,InputNumberModule,InputMaskModule,InputTextModule,DropdownModule,TabMenuModule,TableModule,DynamicDialogModule
     
  ],
  providers: [
    //provideClientHydration()
    ProductService,HttpErrorHandler,MessageServiceS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
 // exports: [AppConfigComponent]
})

export class AppModule { }
