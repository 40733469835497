{
  "name": "pfef",
  "version": "1.3.0 β",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:qa": "ng serve --configuration=qa",
    "build": "ng build",
    "build:development": "ng build --configuration=development",
    "build:qa": "ng build --configuration=qa",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:PFEF": "node dist/pfef/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.0",
    "@angular/common": "^17.2.0",
    "@angular/compiler": "^17.2.0",
    "@angular/core": "^17.2.0",
    "@angular/forms": "^17.2.0",
    "@angular/platform-browser": "^17.2.0",
    "@angular/platform-browser-dynamic": "^17.2.0",
    "@angular/platform-server": "^17.2.0",
    "@angular/router": "^17.2.0",
    "@angular/ssr": "^17.2.3",
    "express": "^4.18.2",
    "firebase": "^10.12.4",
    "ngx-cookie-service": "^18.0.0",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.13.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.3",
    "@angular/cli": "^17.2.3",
    "@angular/compiler-cli": "^17.2.0",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.3.2"
  }
}
