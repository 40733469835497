import { Component, OnInit } from '@angular/core';
import { Product } from '../../demo/api/product'
//import { ProductService } from '../../demo/service/productservice';
import {tipoClienteSustituto} from '../../core/Interfaces/tipo-cliente-sustitutos'
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { sustitutos } from './sustitutos';
import { config } from 'process';

@Component({
    providers: [DialogService, MessageService],
    template: `
<p-table [value]="tipoClienteSustitutos" responsiveLayout="scroll" [rows]="4" [responsive]="true">
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>Nombre</th>
            <th>RFC</th>
            <th>Codigo Postal</th>
            <th>Cliente Sustituto</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tipoClienteSustituto>
        <tr >
            <td>
                <p-button type="button" [text]="true" [rounded]="true" icon="pi pi-plus" (click)="selectClienteSustituto(tipoClienteSustituto)"></p-button>
            </td>
            <td>{{tipoClienteSustituto.Nombre}}</td>
            <td>{{tipoClienteSustituto.RFC}}</td>
            <td>{{tipoClienteSustituto.CodigoPostal}}</td>
            <td>
            <img src="../../../assets/layout/images/true.png" [hidden]="!tipoClienteSustituto.Sustituto" alt="" width="40px">
            <img src="../../../assets/layout/images/false.png" [hidden]="tipoClienteSustituto.Sustituto" alt="" width="40px">
                <!-- {{tipoClienteSustituto.IdSustituto}} -->

            </td>
          
        </tr>
    </ng-template>
</p-table>
        
        `
})


export class SustitutosList implements OnInit {

    //products: Product[] = [];
    tipoClienteSustitutos: tipoClienteSustituto[] = [];
    messageService: any;


    constructor(
        private dialogService: DialogService, private ref: DynamicDialogRef , public config: DynamicDialogConfig) { }

    ngOnInit() {
        
        this.tipoClienteSustitutos = this.config.data;
        
    }

    selectClienteSustituto(TipoClienteSustituto: tipoClienteSustituto) {
       
        this.ref.close(TipoClienteSustituto);
    }

    showInfo() {
        this.dialogService.open(sustitutos, {
            header: 'Clientes Registrados',
            modal: true,
            dismissableMask: true,
            data: {
                tipoClienteSustitutos: this.tipoClienteSustitutos ? this.tipoClienteSustitutos.length : 0
            }
        });
    }

}